import { authHeader, postAuthHeader, handleResponse } from '../_helpers';
import { stringService, wizardPropertyService } from '../_services';

const API_URL = process.env.REACT_APP_API_URL;

export const zoneService = {
    getList,
    create
};

function getList() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v1/zone`, requestOptions).then(handleResponse);
}

function create(COMPANY_NAME, COMPANY_PK) {

    let FRAN = COMPANY_NAME;
    let ENGL = COMPANY_NAME;

    let ID = COMPANY_PK + wizardPropertyService.getDefaultZoneIDSuffix();
    let createdZone;

    return createZone(ID, FRAN, 'N')
    .then(zone => {
        stringService.update(zone.STRINGID, FRAN, ENGL);
        createdZone = zone
        return createdZone;
    })
    .then(() => getFamilyEquipmentList('CIT'))
    .then(citFamilies => {
        const promises = [];
        citFamilies.map((citFamily) => promises.push(createZoneFamilyEquipment(ID, citFamily.FAMILY_PK)));
        return Promise.all(promises);
    })
    .then(() => getFamilyItemList('CIT'))
    .then(citFamilies => {
        const promises = [];
        citFamilies.map((citFamily) => promises.push(createZoneFamilyItem(ID, citFamily.FAMILY_PK)));
        return Promise.all(promises);
    })
    .then(() => {
        const promises = [];
        wizardPropertyService.getDefaultZoneCategories().map(CATEGORY_PK => promises.push(createZoneCategory(ID, CATEGORY_PK, 'N')));
        return Promise.all(promises);
    })
    .then(() => createCitZoneSyncSystem(COMPANY_PK, COMPANY_NAME, ID))
    .then(() => createdZone)
    .catch(e => {
        throw new Error("Erreur lors de la création de la zone: " + e);
    });
}

function createZone(ID, DESIGNATION, FLAG_SYSTEM) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ ID, DESIGNATION, FLAG_SYSTEM })
    };

    return fetch(`${API_URL}/api/v1/zone`, requestOptions).then(handleResponse);
}

function getFamilyEquipmentList(ZONE_PK) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v1/zone/family?` + new URLSearchParams({
        ZONE_PK: ZONE_PK,
    }), requestOptions).then(handleResponse);
}

function createZoneFamilyEquipment(ZONE_PK, FAMILY_PK) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ ZONE_PK, FAMILY_PK })
    };

    return fetch(`${API_URL}/api/v1/zone/family`, requestOptions).then(handleResponse);
}

function getFamilyItemList(ZONE_PK) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v1/zone/family/item?` + new URLSearchParams({
        ZONE_PK: ZONE_PK,
    }), requestOptions).then(handleResponse);
}

function createZoneFamilyItem(ZONE_PK, FAMILY_PK) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ ZONE_PK, FAMILY_PK })
    };

    return fetch(`${API_URL}/api/v1/zone/family/item`, requestOptions).then(handleResponse);
}

function createZoneCategory(ZONE_PK, CATEGORY_PK, ADD_CHILD) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ ZONE_PK, CATEGORY_PK, ADD_CHILD })
    };

    return fetch(`${API_URL}/api/v1/zone/category`, requestOptions).then(handleResponse);
}

function createCitZoneSyncSystem(ID, NAME, ZONE_PK) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ ID, NAME, ZONE_PK })
    };

    // Calling temp implementation to update cit zone sync system
    // Will be refactor when logic will moved to the API
    return fetch(`${API_URL}/api/v1/wizard/municipality`, requestOptions).then(handleResponse);
}