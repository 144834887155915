// TODO Implement from a DB
// - Change hardcoded labels to getString or something, see SEN_STRINGS
// - Change hardcoded props to getPreference or something, see NJ_PREFERENCE

export const wizardPropertyService = {
    getDefaultZoneIDSuffix,
    getDefaultTeamIDSuffix,
    getDefaultWarehouseIDSuffix,
    getDefaultWarehouseLabel,
    getDefaultTopOwnerPKSuffix,
    getDefaultOwners,
    getDefaultMunClientPKSuffix,
    getDefaultPrivClientPKSuffix,
    getDefaultMtqClientPKSuffix,
    getDefaultPrivClientLabel,
    getDefaultMtqClientLabel,
    getWastewaterRecoveryCategories,
    getWastewaterRecoveryOwnerPKSuffix,
    getWastewaterPurificationCategories,
    getWastewaterPurificationOwnerPKSuffix,
    getWastewaterCollectionCategories,
    getWastewaterCollectionOwnerPKSuffix,
    getWastewaterPumpingCategories,
    getWastewaterPumpingOwnerPKSuffix,
    getDrinkingWaterSupplyCategories,
    getDrinkingWaterSupplyOwnerPKSuffix,
    getDrinkingWaterDistributionCategories,
    getDrinkingWaterDistributionOwnerPKSuffix,
    getDrinkingWaterDistributionFailureCauses,
    getDrinkingWaterSupplyTreatementOwnerPKSuffix,
    getDrinkingWaterSupplyTreatementCategories,
    getRainwaterCategories,
    getRainwaterOwnerPKSuffix,
    getRainwaterTreatmentAndRetentionCategories,
    getRainwaterTreatmentAndRetentionOwnerPKSuffix,
    getRainwaterCollectionCategories,
    getRainwaterCollectionOwnerPKSuffix,
    getRoadwayCategories,
    getRoadwayOwnerPKSuffix,
    getDefaultLanguagePK,
    getDefaultZoneCategories,
    getMunicipalityPersonas,
    getCommonRoleNames,
    getCitadelProducts,
    getDefaultTemplateNames,
    getMaintenanceOpTemplateNames,
};

function getDefaultZoneIDSuffix() {
    return 'T';
}

function getDefaultTeamIDSuffix() {
    return 'T01';
}

function getDefaultWarehouseIDSuffix() {
    return 'G';
}

function getDefaultWarehouseLabel() {
    return { 'ENGL': 'Municipal Garage', 'FRAN': 'Garage municipal'};
}

function getDefaultTopOwnerPKSuffix() {
    return 'MUN';
}

function getDefaultOwners() {
    return [
        {'OWNER_PK': 'EPO' , 'OWNER_NODE': 'MUN' , 'ENGL': 'Drinking water supply' , 'FRAN': 'Alimentation en eau potable'},
        {'OWNER_PK': 'EPOP' , 'OWNER_NODE': 'EPO' , 'ENGL': 'Drinking water supply and treatement' , 'FRAN': 'Alimentation et traitement - Eau potable'},
        {'OWNER_PK': 'EPOD' , 'OWNER_NODE': 'EPO' , 'ENGL': 'Drinking water distribution' , 'FRAN': 'Distribution - Eau potable'},
        {'OWNER_PK': 'EUS' , 'OWNER_NODE': 'MUN' , 'ENGL': 'Wastewater recovery' , 'FRAN': 'Récupération des eaux usées'},
        {'OWNER_PK': 'EUSE' , 'OWNER_NODE': 'EUS' , 'ENGL': 'Wastewater Purification' , 'FRAN': 'Épuration des eaux usées'},
        {'OWNER_PK': 'EUSC' , 'OWNER_NODE': 'EUS' , 'ENGL': 'Wastewater Collection' , 'FRAN': 'Collecte - eaux usées'},
        {'OWNER_PK': 'EUSP' , 'OWNER_NODE': 'EUS' , 'ENGL': 'Wastewater pumping' , 'FRAN': 'Pompage - eaux usées'},
        {'OWNER_PK': 'EPL' , 'OWNER_NODE': 'MUN' , 'ENGL': 'Rainwater' , 'FRAN': 'Eaux pluviales'},
        {'OWNER_PK': 'EPLT' , 'OWNER_NODE': 'EPL' , 'ENGL': 'Rainwater Treatment and retention' , 'FRAN': 'Traitement et rétention - eaux pluviales'},
        {'OWNER_PK': 'EPLC' , 'OWNER_NODE': 'EPL' , 'ENGL': 'Rainwater Collection' , 'FRAN': 'Collecte - eaux pluviales'},
        {'OWNER_PK': 'TRA' , 'OWNER_NODE': 'MUN' , 'ENGL': 'Transportation' , 'FRAN': 'Transports'},
        {'OWNER_PK': 'TRAR' , 'OWNER_NODE': 'TRA' , 'ENGL': 'Road' , 'FRAN': 'Routier'},
        {'OWNER_PK': 'TRARS' , 'OWNER_NODE': 'TRAR' , 'ENGL': 'Road sign' , 'FRAN': 'Signalisation'},
        {'OWNER_PK': 'TRARE' , 'OWNER_NODE': 'TRAR' , 'ENGL': 'Lighting' , 'FRAN': 'Éclairage'},
        {'OWNER_PK': 'TRARC' , 'OWNER_NODE': 'TRAR' , 'ENGL': 'Roadway' , 'FRAN': 'Chaussée'},
        {'OWNER_PK': 'TRAP' , 'OWNER_NODE': 'TRA' , 'ENGL': 'Path' , 'FRAN': 'Sentier et piste cyclable'},
        {'OWNER_PK': 'TRAS' , 'OWNER_NODE': 'TRA' , 'ENGL': 'Parking' , 'FRAN': 'Stationnement'},
        {'OWNER_PK': 'SEC' , 'OWNER_NODE': 'MUN' , 'ENGL': 'Security' , 'FRAN': 'Sécurité'},
        {'OWNER_PK': 'SECPUB' , 'OWNER_NODE': 'SEC' , 'ENGL': 'Public Security' , 'FRAN': 'Sécurité publique'},
        {'OWNER_PK': 'SECINC' , 'OWNER_NODE': 'SEC' , 'ENGL': 'Fire Departement' , 'FRAN': 'Service d\'incendie'},
        {'OWNER_PK': 'FLV' , 'OWNER_NODE': 'MUN' , 'ENGL': 'Fleet of vehicles' , 'FRAN': 'Flotte de véhicules'},
        {'OWNER_PK': 'SLC' , 'OWNER_NODE': 'MUN' , 'ENGL': 'Sports, Leisure, Culture' , 'FRAN': 'Sports, Loisirs et Culture'},
        {'OWNER_PK': 'SLCARENA' , 'OWNER_NODE': 'SLC' , 'ENGL': 'Arena' , 'FRAN': 'Arèna'},
        {'OWNER_PK': 'SLCBIBLI' , 'OWNER_NODE': 'SLC' , 'ENGL': 'Library' , 'FRAN': 'Bibliothèque'},
        {'OWNER_PK': 'SLCP' , 'OWNER_NODE': 'SLC' , 'ENGL': 'Park' , 'FRAN': 'Parc'},
        {'OWNER_PK': 'SLCPJ' , 'OWNER_NODE': 'SLC' , 'ENGL': 'Playground' , 'FRAN': 'Modules de jeux'},
        {'OWNER_PK': 'SLCPC' , 'OWNER_NODE': 'SLC' , 'ENGL': 'Park Chalet' , 'FRAN': 'Chalet de parc'},
        {'OWNER_PK': 'GMA' , 'OWNER_NODE': 'MUN' , 'ENGL': 'Waste management' , 'FRAN': 'Gestion des matières résiduelles'},
        {'OWNER_PK': 'GMATRI' , 'OWNER_NODE': 'GMA' , 'ENGL': 'Sorting Center' , 'FRAN': 'Centre de tri'},
        {'OWNER_PK': 'GMAPOU' , 'OWNER_NODE': 'GMA' , 'ENGL': 'Waste bins and containers' , 'FRAN': 'Poubelles et conteneurs'},
        {'OWNER_PK': 'NAT' , 'OWNER_NODE': 'MUN' , 'ENGL': 'Natural Assets' , 'FRAN': 'Actifs naturels'},
        {'OWNER_PK': 'NATHOR' , 'OWNER_NODE': 'NAT' , 'ENGL': 'Horticulture' , 'FRAN': 'Horticulture'},
        {'OWNER_PK': 'NATFOR' , 'OWNER_NODE': 'NAT' , 'ENGL': 'Forestry' , 'FRAN': 'Foresterie'},
        {'OWNER_PK': 'NATB' , 'OWNER_NODE': 'NAT' , 'ENGL': 'Dams' , 'FRAN': 'Barrages'},
        {'OWNER_PK': 'NATSL' , 'OWNER_NODE': 'NAT' , 'ENGL': 'Lakes' , 'FRAN': 'Lacs'},
        {'OWNER_PK': 'RUE' , 'OWNER_NODE': 'MUN' , 'ENGL': 'Streets and integrated segments' , 'FRAN': 'Rue et tronçons intégrés'},
        {'OWNER_PK': 'TI' , 'OWNER_NODE': 'MUN' , 'ENGL': 'IT Department' , 'FRAN': 'Département TI'},
        {'OWNER_PK': 'GBAT' , 'OWNER_NODE': 'MUN' , 'ENGL': 'Building management' , 'FRAN': 'Gestion des bâtiments'}
    ];
}

function getDefaultMunClientPKSuffix() {
    return 'MUN';
}

function getDefaultPrivClientPKSuffix() {
    return 'PRIV';
}

function getDefaultMtqClientPKSuffix() {
    return 'MTQ';
}

function getDefaultPrivClientLabel() {
    return 'Privé';
}

function getDefaultMtqClientLabel() {
    return 'MTQ';
}

function getWastewaterRecoveryCategories() {
    return ['RS', 'RNS', 'CCTV', 'TO', 'V'];
}

function getWastewaterRecoveryOwnerPKSuffix() {
    return 'EUS';
}

function getWastewaterPurificationCategories() {
    return ['RS', 'RNS', 'CCTV', 'TO', 'V'];
}

function getWastewaterPurificationOwnerPKSuffix() {
    return 'EUSE';
}

function getWastewaterCollectionCategories() {
    return ['RS', 'RNS', 'CCTV', 'TO', 'V'];
}

function getWastewaterCollectionOwnerPKSuffix() {
    return 'EUSC';
}

function getWastewaterPumpingCategories() {
    return ['PP1', 'PP2', 'RS', 'RNS', 'CCTV', 'TO', 'V'];
}

function getWastewaterPumpingOwnerPKSuffix() {
    return 'EUSP';
}

function getDrinkingWaterSupplyCategories() {
    return ['AQA', 'RS', 'RNS', 'CCTV', 'TO', 'V'];
}

function getDrinkingWaterSupplyOwnerPKSuffix() {
    return 'EPO';
}

function getDrinkingWaterDistributionCategories() {
    return ['AQA', 'AQB', 'RS', 'RNS', 'CCTV', 'TO', 'V'];
}

function getDrinkingWaterDistributionOwnerPKSuffix() {
    return 'EPOD';
}

function getDrinkingWaterDistributionFailureCauses() {
    return ['B1', 'B2', 'B3', 'B4','B5'];
}

function getDrinkingWaterSupplyTreatementOwnerPKSuffix() {
    return 'EPOP';
}

function getDrinkingWaterSupplyTreatementCategories() {
    return ['AQA', 'RS', 'RNS', 'CCTV', 'TO', 'V'];
}

function getRainwaterCategories() {
    return ['RS', 'RNS', 'CCTV', 'TO', 'V'];
}

function getRainwaterOwnerPKSuffix() {
    return 'EPL';
}

function getRainwaterTreatmentAndRetentionCategories() {
    return ['RS', 'RNS', 'CCTV', 'TO', 'V'];
}

function getRainwaterTreatmentAndRetentionOwnerPKSuffix() {
    return 'EPLT';
}

function getRainwaterCollectionCategories() {
    return ['RS', 'RNS', 'CCTV', 'TO', 'V'];
}

function getRainwaterCollectionOwnerPKSuffix() {
    return 'EPLC';
}

function getRoadwayCategories() {
    return ['RC', 'PP', 'R'];
}

function getRoadwayOwnerPKSuffix() {
    return 'TRARC';
}

function getDefaultZoneCategories() {
    return ['PP1', 'PP2', 'AQA', 'AQB', 'RS', 'RNS', 'CCTV', 'TO', 'V', 'RC', 'PP', 'R'];
}

function getDefaultLanguagePK() {
    return 'FRAN';
}

function getCommonRoleNames() {
    return ['NJ_LOGIN', 'NJ_MOBILE', 'CIT_DASHBOARD'];
} 

function getMunicipalityPersonas() {
    return {
        'NONE': {
            'FRAN': 'Produit désactivé',
            'ROLE_SUFFIX_LIST' : [],
        },
        'ADMIN': {
            'FRAN': 'Administrateur',
            'ROLE_SUFFIX_LIST' : ['_ADMIN', '_SUPRV', '_OP', '_LECTEUR'],
        },
        'SUPRV': {
            'FRAN': 'Superviseur',
            'ROLE_SUFFIX_LIST' : ['_SUPRV'],
        },
        'OP': {
            'FRAN': 'Opérateur',
            'ROLE_SUFFIX_LIST' : ['_OP'],
        },
        'READER': {
            'FRAN': 'Lecteur',
            'ROLE_SUFFIX_LIST' : ['_LECTEUR'],
        },
    };
}

function getCitadelProducts() {
    return {
        'MOD_ASSET': {
            'ROLE_PREFIX' : 'CIT_GESTION_ACTIFS',
        },
        'MOD_MAINT': {
            'ROLE_PREFIX' : 'CIT_GESTION_MAINTENANCE',
        },
        'MOD_STOCK': {
            'ROLE_PREFIX' : 'CIT_GESTION_STOCKS',
        },
    };
}

function getDefaultTemplateNames() {
    return [
        'Citadel - Actif BASE', 
        'Citadel - Analyse Actif DEFAULT',
        'Citadel - Historique Actif DEFAULT',
        'Citadel - Municipalité DEFAULT',
        'Citadel - Bon de travail BASE',
        'Citadel - Maintenance à faire DEFAULT',
        'Citadel - Bon de travail / Employé DEFAULT',
        'Citadel - Demande d\'intervention DEFAULT',
        'Citadel - Gamme de référence DEFAULT',
        'Citadel - Gamme appliquée DEFAULT',
        'Citadel - Inventaire DEFAULT',
        'Citadel - Département DEFAULT',
        'Citadel - Gestion département DEFAULT',
        'Citadel - Mini-fiche Carte DEFAULT',
        'Citadel - Gestionnaires d\'actifs DEFAULT',
    ];
}

function getMaintenanceOpTemplateNames() {
    return [
        'Citadel - Bon de travail Operateur', 
    ];
}