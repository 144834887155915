import React from 'react';

import { companyService, agencyService, managementUnitService, zoneService, teamService, warehouseService, ownerService, clientService } from '../_services';
import { municipalityManager } from '../_managers';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import * as Yup from 'yup';

class CreateMunicipalityPage extends React.Component {

    render() {
        return (
            <div>
                <h1>Créer une municipalité</h1>
                <div className="alert alert-info">
                    Veuillez tout d'abord indiquer les informations de base sur votre municipalité.
                </div>
                <Formik
                    initialValues={{
                        STATUTE: 'municipality',
                        NAME: '',
                        ID: '',
                        ADDRESS: '',
                        TEL: '', 
                        FAX: ''
                    }}
                    validationSchema={Yup.object().shape({
                        STATUTE: Yup.string()
                            .required("Le statut est requis"),
                        NAME: Yup.string()
                            .required("Le nom de la municipalité est requis")
                            .max(60, "Maximum de 60 caractères"),
                        TEL: Yup.string()
                            .max(20, "Maximum de 20 caractères"),
                        FAX: Yup.string()
                            .max(20, "Maximum de 20 caractères"),
                        ADDRESS: Yup.string()
                            .max(250, "Maximum de 250 caractères"),
                        ID: Yup.string()
                            .required("L'identifiant est requis")
                            .length(2, "L'identifiant doit être composé de 2 caractères")
                    })}
                    onSubmit={({ ID, NAME, ADDRESS, TEL, FAX, STATUTE }, { setStatus, setSubmitting }) => {
                        setStatus({ creationProgressLabel : "Vérification de la disponibilité de l'identifiant ...", creationProgress: 5});

                        municipalityManager.verifyId(ID)
                        .then(() => {
                            setStatus({ creationProgressLabel : "Création de la nouvelle Municipalité ...", creationProgress: 10});
                            return companyService.create(ID, NAME, ADDRESS, TEL, FAX, STATUTE);
                        })
                        .then(company => {
                            setStatus({ creationProgressLabel : "Création du Service par défaut ...", creationProgress: 15});
                            return agencyService.create(NAME, ID);
                        })
                        .then(agency => {
                            setStatus({ creationProgressLabel : "Création du Groupe de gestion par défaut ...", creationProgress: 20});
                            return managementUnitService.create(NAME);
                        })
                        .then(managementUnit => {
                            setStatus({ creationProgressLabel : "Création de la Zone par défaut ...", creationProgress: 25});
                            return zoneService.create(NAME, ID);
                        })
                        .then(zone => {
                            setStatus({ creationProgressLabel : "Création de l'Équipe par défaut ...", creationProgress: 55});
                            return teamService.create(NAME, ID, zone.ZONE_PK);
                        })
                        .then(team => {
                            setStatus({ creationProgressLabel : "Création du Magasin par défaut ...", creationProgress: 60});
                            return warehouseService.create(NAME, ID);
                        })
                        .then(warehouse => {
                            setStatus({ creationProgressLabel : "Création des Départements ...", creationProgress: 65});
                            return ownerService.create(NAME, ID, STATUTE);
                        })
                        .then(owner => {
                            setStatus({ creationProgressLabel : "Création des Gestionnaires d'actif par défaut ...", creationProgress: 95});
                            return clientService.create(NAME, ID, ADDRESS, TEL, FAX, STATUTE);
                        })
                        .then(client => {
                            setStatus({ creationProgressLabel : "Création terminée avec succès!", creationProgress: 100});
                            return new Promise(resolve => setTimeout(resolve, 2000))
                            .then(() => this.props.history.push("/"));
                        })
                        .catch(e => {
                            setSubmitting(false);
                            setStatus({ creationError : e.message || e || "Erreur lors de la création d'un élément de la municipalité"});
                        });

                    }}
                    onReset={() => {
                        this.props.history.push('/create-intro');;
                    }}
                >
                    {({ errors, status, touched, isSubmitting }) => (
                        <Form>
                            <div className="form-group row">
                                <label htmlFor="STATUTE" className="col-sm-3 col-form-label">Statut *</label>
                                <div className="col-sm-5">
                                    <Field as="select" name="STATUTE" className="btn btn-info dropdown-toggle text-left">
                                        <option value="municipality">Municipalité</option>
                                        <option value="township_municipality">Municipalité de canton</option>
                                        <option value="parish_municipality">Municipalité de paroisse</option>
                                        <option value="village_municipality">Municipalité de village</option>
                                        <option value="indian_reserve">Réserve indienne</option>
                                        <option value="city">Ville</option>
                                    </Field>
                                    <ErrorMessage name="STATUTE" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="NAME" className="col-sm-3 col-form-label">Nom *</label>
                                <div className="col-sm-6">
                                    <Field name="NAME" type="text" className={'form-control' + (errors.NAME && touched.NAME ? ' is-invalid' : '')} />
                                    <ErrorMessage name="NAME" component="div" className="invalid-feedback" />
                                    <small id="nameHelpInline" className="text-muted">
                                    Tel qu'il apparaît dans la 
                                    <a target='blank' href="https://toponymie.gouv.qc.ca/ct/toponymie-municipale/municipalites-arrondissements/toponymie_municipale.aspx"> liste officielle</a> de la Comission de toponymie du Québec
                                    </small>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="ID" className="col-sm-3 col-form-label">Identifiant *</label>
                                <div className="col-sm-6">
                                    <Field name="ID" type="text" className={'form-control' + (errors.ID && touched.ID ? ' is-invalid' : '')} />
                                    <ErrorMessage name="ID" component="div" className="invalid-feedback" />
                                    <small id="idHelpInline" className="text-muted">
                                        Deux caractères: i.e: AA
                                    </small>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="ADDRESS" className="col-sm-3 col-form-label">Adresse</label>
                                <div className="col-sm-6">
                                    <Field as="textarea" name="ADDRESS" type="text" className={'form-control' + (errors.ADDRESS && touched.ADDRESS ? ' is-invalid' : '')} />
                                    <ErrorMessage name="ADDRESS" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="TEL" className="col-sm-3 col-form-label">Téléphone</label>
                                <div className="col-sm-6">
                                    <Field name="TEL" type="text" className={'form-control' + (errors.TEL && touched.TEL ? ' is-invalid' : '')} />
                                    <ErrorMessage name="TEL" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="FAX" className="col-sm-3 col-form-label">Fax</label>
                                <div className="col-sm-6">
                                    <Field name="FAX" type="text" className={'form-control' + (errors.FAX && touched.FAX ? ' is-invalid' : '')} />
                                    <ErrorMessage name="FAX" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            {status && status.creationError && 
                                <div className={'alert alert-danger'}>{status.creationError}</div>
                            }
                            {status && status.creationProgress &&
                                <div>
                                    <div className={'alert alert-info'}>{status.creationProgressLabel}</div>
                                    <div className="progress" style={{ height: "25px", "marginBottom": "15px" }}> 
                                        <div className={'progress-bar progress-bar-striped progress-bar-animated'} style={{width: status.creationProgress + "%", height: "25px"}}></div>
                                    </div>

                                </div>
                            }
                            <div className="form-group">
                                <button type="reset" className="btn btn-secondary float-left" disabled={isSubmitting}>Retour à l'introduction</button>
                                <button type="submit" className="btn btn-primary float-right" disabled={isSubmitting}>Créer la municipalité</button>
                                {isSubmitting &&
                                    <img alt='Veuillez patienter' src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export { CreateMunicipalityPage };