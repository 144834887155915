import React from 'react';
import { Router, Route, Link } from 'react-router-dom';

import { history } from '../_helpers';
import { authenticationManager } from '../_managers';
import { PrivateRoute } from '../_components';
import { HomePage } from '../HomePage';
import { LoginPage } from '../LoginPage';
import { CreateIntroPage } from '../CreateIntroPage';
import { CreateMunicipalityPage } from '../CreateMunicipalityPage';
import { CreateUserPage } from '../CreateUserPage';
import { DeleteAccountPage } from '../DeleteAccountPage';

import { municipalityManager } from '../_managers';

import logo from './citadel_logo.svg';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            currentPermissions: null
        };
    }

    componentDidMount() {
        authenticationManager.currentUser.subscribe(x => this.setState({ currentUser: x }));
        authenticationManager.currentPermissions.subscribe(x => this.setState({ currentPermissions: x }));
    }

    logout() {
        authenticationManager.logout();
        history.push('/login');
    }

    render() {
        const { currentUser } = this.state;
        return (
            <Router history={history}>
                <div>
                    {currentUser &&
                        <nav className="navbar navbar-expand navbar-light" style={{"backgroundColor": "#e3f2fd"}}>
                            <div className="navbar-nav">
                                <Link to="/" className="navbar-brand"><img src={logo} alt="logo" style={{"width": "70px"}} /></Link>
                                { municipalityManager.permissionToCreateMunicipality() &&
                                <Link to="/create-intro" className="nav-item nav-link">Créer une municipalité</Link>
                                }
                                { municipalityManager.permissionToCreateUser() &&
                                <Link to="/create-user" className="nav-item nav-link">Créer un utilisateur</Link>
                                }
                            </div>
                            <div className="navbar-nav ml-auto">
                                <a href='/' onClick={this.logout} className="nav-item nav-link">Déconnexion</a>
                            </div>
                        </nav>
                    }
                    <div className="jumbotron">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <Route path="/login" component={LoginPage} />
                                    <PrivateRoute exact path="/" component={HomePage} />
                                    <PrivateRoute exact path="/create-intro" component={CreateIntroPage} />
                                    <PrivateRoute exact path="/create-municipality" component={CreateMunicipalityPage} />
                                    <PrivateRoute exact path="/create-user" component={CreateUserPage} />
                                    <PrivateRoute exact path="/delete-my-account" component={DeleteAccountPage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Router>
        );
    }
}

export { App }; 