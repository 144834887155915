import { authHeader, postAuthHeader, handleResponse } from '../_helpers';
import { wizardPropertyService, roleService, objectTemplateService } from '../_services';
import { agencyService } from './agency.service';

const API_URL = process.env.REACT_APP_API_URL;

export const userService = {
    login,
    getList,
    getProfile,
    getGrantList,
    create,
    update,
    getManagementUnitList,
    createUserOwner
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    
    return fetch(`${API_URL}/api/v1/user/login`, requestOptions).then(handleResponse);
}

function getList() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v1/user`, requestOptions).then(handleResponse);
}

function getProfile() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v1/user/profile`, requestOptions).then(handleResponse);
}

function getGrantList() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v1/user/grant`, requestOptions).then(handleResponse);
}

function create(COMPANY_PK, USER_NAME, PASSWORD, EMPLOYEE_PK, PARENT_USER_PK, MOD_ASSET, MOD_MAINT, MOD_STOCK, MANAGEMENT_UNIT_PK) {
    let ZONE_PK = COMPANY_PK + wizardPropertyService.getDefaultZoneIDSuffix();
    let LANGUAGE_PK = wizardPropertyService.getDefaultLanguagePK();
    let createdUser;
    let roleList;

    return createUser(USER_NAME, ZONE_PK, LANGUAGE_PK, EMPLOYEE_PK, PARENT_USER_PK, PASSWORD)
    .then(user => {
        createdUser = user;
    })
    .then(() => roleService.getList())
    .then(roles => {
        // All Citadel roles name mapped to role PK
        let roleNameMap = {};
        roles.map(role => roleNameMap[role.ROLE_NAME] = role.ROLE_PK);

        // Prepare role list
        roleList = wizardPropertyService.getCommonRoleNames();

        for (let role_suffix of wizardPropertyService.getMunicipalityPersonas()[MOD_ASSET].ROLE_SUFFIX_LIST) {
            roleList.push(wizardPropertyService.getCitadelProducts()['MOD_ASSET'].ROLE_PREFIX + role_suffix);
        }
        for (let role_suffix of wizardPropertyService.getMunicipalityPersonas()[MOD_MAINT].ROLE_SUFFIX_LIST) {
            roleList.push(wizardPropertyService.getCitadelProducts()['MOD_MAINT'].ROLE_PREFIX + role_suffix);
        }
        for (let role_suffix of wizardPropertyService.getMunicipalityPersonas()[MOD_STOCK].ROLE_SUFFIX_LIST) {
            roleList.push(wizardPropertyService.getCitadelProducts()['MOD_STOCK'].ROLE_PREFIX + role_suffix);
        }

        // Create user roles
        const promises = [];
        roleList.map(roleName => promises.push(createUserRole(createdUser.USER_PK, roleNameMap[roleName])));
        return Promise.all(promises);
    })
    .then(() => {
        let topOwnerPK = COMPANY_PK + wizardPropertyService.getDefaultTopOwnerPKSuffix();
        return createUserOwner(createdUser.USER_PK, topOwnerPK, 'Y');
    })
    .then(() => {
        let WAREHOUSE_PK = COMPANY_PK + wizardPropertyService.getDefaultWarehouseIDSuffix();
        return createUserWarehouse(createdUser.USER_PK, WAREHOUSE_PK, 'N');
    })
    .then(() => {
        return createUserCompany(createdUser.USER_PK, COMPANY_PK, 'Y', 'N');
    })
    .then(() => {
        return agencyService.getList()
        .then(agencies => {
            const promises = [];
            for (let agency of agencies) {
                if (agency.COMPANY_PK === COMPANY_PK) {
                    promises.push(createUserAgency(createdUser.USER_PK, agency.AGENCY_PK, 'N'));
                }
            }
            return Promise.all(promises);
        })
    })
    .then(() => {
        return createUserManagementUnit(createdUser.USER_PK, MANAGEMENT_UNIT_PK, 'N')
    })
    .then(() => objectTemplateService.getList())
    .then(objectTemplates => {
        // All Citadel template name mapped to template PK
        let templateNameMap = {};
        objectTemplates.map(objectTemplate => templateNameMap[objectTemplate.DESIGNATION] = objectTemplate.OBJECT_TEMPLATE_PK);

        // Prepare template list
        let templateList = [];

        // Add default templates
        templateList = templateList.concat(wizardPropertyService.getDefaultTemplateNames());

        // Add custom templates
        if (MOD_MAINT === 'OP' || MOD_MAINT === 'READER') {
            templateList = templateList.concat(wizardPropertyService.getMaintenanceOpTemplateNames());
        }

        // Remove any duplicates
        templateList = [...new Set(templateList)]; 

        // Create user templates
        const promises = [];
        templateList.map(templateName => promises.push(createUserObjectTemplate(createdUser.USER_PK, templateNameMap[templateName])));
        return Promise.all(promises);
    })
    .then(() => createdUser)
    .catch(e => {
        throw new Error("Erreur lors de la création de l'utilisateur: " + e);
    });
}

function update(USER_PK, COMPANY_PK, USER_NAME, EMPLOYEE_PK, PARENT_USER_PK) {
    let ZONE_PK = COMPANY_PK + wizardPropertyService.getDefaultZoneIDSuffix();
    let LANGUAGE_PK = wizardPropertyService.getDefaultLanguagePK();
    
    return updateUser(USER_PK, USER_NAME, ZONE_PK, LANGUAGE_PK, EMPLOYEE_PK, PARENT_USER_PK)
    .catch(e => {
        throw new Error("Erreur lors de la modification de l'utilisateur: " + e);
    });
}

function createUser(USER_NAME, ZONE_PK, LANGUAGE_PK, EMPLOYEE_PK, PARENT_USER_PK, PASSWORD) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ USER_NAME, ZONE_PK, LANGUAGE_PK, EMPLOYEE_PK, PARENT_USER_PK, PASSWORD })
    };

    return fetch(`${API_URL}/api/v1/user`, requestOptions).then(handleResponse);
}

function updateUser(USER_PK, USER_NAME, ZONE_PK, LANGUAGE_PK, EMPLOYEE_PK, PARENT_USER_PK) {
    const requestOptions = { 
        method: 'PATCH', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ USER_NAME, ZONE_PK, LANGUAGE_PK, EMPLOYEE_PK, PARENT_USER_PK })
    };

    return fetch(`${API_URL}/api/v1/user/${USER_PK}`, requestOptions).then(handleResponse);
}

function createUserRole(USER_PK, ROLE_PK) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ USER_PK, ROLE_PK })
    };

    return fetch(`${API_URL}/api/v1/user/role`, requestOptions).then(handleResponse);
}

function createUserOwner(USER_PK, OWNER_PK, ADD_CHILD) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ USER_PK, OWNER_PK, ADD_CHILD })
    };

    return fetch(`${API_URL}/api/v1/user/owner`, requestOptions).then(handleResponse);
}

function createUserWarehouse(USER_PK, WAREHOUSE_PK, LINK_ALL) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ USER_PK, WAREHOUSE_PK, LINK_ALL })
    };

    return fetch(`${API_URL}/api/v1/user/warehouse`, requestOptions).then(handleResponse);
}

function createUserCompany(USER_PK, COMPANY_PK, FLAG_DEFAULT_COMPANY, LINK_ALL) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ USER_PK, COMPANY_PK, FLAG_DEFAULT_COMPANY, LINK_ALL })
    };

    return fetch(`${API_URL}/api/v1/user/company`, requestOptions).then(handleResponse);
}

function createUserAgency(USER_PK, AGENCY_PK, LINK_ALL) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ USER_PK, AGENCY_PK, LINK_ALL })
    };

    return fetch(`${API_URL}/api/v1/user/agency`, requestOptions).then(handleResponse);
}

function createUserManagementUnit(USER_PK, MANAGEMENT_UNIT_PK, LINK_ALL) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ USER_PK, MANAGEMENT_UNIT_PK, LINK_ALL })
    };

    return fetch(`${API_URL}/api/v1/user/managementunit`, requestOptions).then(handleResponse);
}

function createUserObjectTemplate(USER_PK, OBJECT_TEMPLATE_PK) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ USER_PK, OBJECT_TEMPLATE_PK })
    };

    return fetch(`${API_URL}/api/v1/user/objecttemplate`, requestOptions).then(handleResponse);
}

function getManagementUnitList(USER_PK) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v1/user/managementunit?` + new URLSearchParams({
        USER_PK: USER_PK,
    }), requestOptions).then(handleResponse);
}