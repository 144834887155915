import { authHeader, handleResponse } from '../_helpers';

const API_URL = process.env.REACT_APP_API_URL;

export const roleService = {
    getList
};

function getList() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v1/role`, requestOptions).then(handleResponse);
}
