import { BehaviorSubject } from 'rxjs';

import { accountService, userService } from '../_services';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
const currentPermissionsSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentPermissions')));

export const authenticationManager = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value },
    currentPermissions: currentPermissionsSubject.asObservable(),
    get currentPermissionsValue () { return currentPermissionsSubject.value }
};

function login(username, password) {
    let currentUser;
    let deletable;

    return userService.login(username, password)
    .then(user => {
        currentUser = user;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        currentUserSubject.next(currentUser);
    })
    .then(() => accountService.deletable())
    .then(deletableResponse => {
        deletable = deletableResponse['deletable'];
    })
    .then(() => userService.getGrantList())
    .then(grants => {
        grants['deletable'] = deletable ? 'Y' : 'N';
        // store permissions details in local storage
        localStorage.setItem('currentPermissions', JSON.stringify(grants));
        currentPermissionsSubject.next(grants);
    })
    .then(() => currentUser);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    
    // remove permissions from local storage
    localStorage.removeItem('currentPermissions');
    currentPermissionsSubject.next(null);
}
