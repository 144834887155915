import React from 'react';

import { history } from '../_helpers';
import { authenticationManager } from '../_managers';
import { accountService } from '../_services';

import { Formik, Form } from 'formik';


class DeleteAccountPage extends React.Component {

    logout() {
        authenticationManager.logout();
        history.push('/login');
    }

    render() {
        return (
            <div>
                <h1>Supprimer mon compte</h1>
                <div className="alert alert-warning">
                    Voulez-vous vraiment supprimer votre compte? Cette action est irréversible.
                </div>
                <Formik
                    initialValues={{
                        DELETE_MY_ACCOUNT: 'yes',
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus({ creationProgressLabel : "Suppression du compte ...", creationProgress: 10});

                        accountService.deleteTrial()
                        .then(() => {
                            setStatus({ creationProgressLabel : "Compte supprimé avec succès!", creationProgress: 100});
                            return new Promise(resolve => setTimeout(resolve, 2000))
                            .then(() => this.logout());
                        })
                        .catch(e => {
                            setSubmitting(false);
                            setStatus({ deleteError : e.message || e || "Erreur lors de la suppression du compte"});
                        });

                    }}
                    onReset={() => {
                        this.props.history.push('/');;
                    }}
                >
                    {({ errors, status, touched, isSubmitting }) => (
                        <Form>
                            {status && status.deleteError && 
                                <div className={'alert alert-danger'}>{status.deleteError}</div>
                            }
                            {status && status.creationProgress &&
                                <div>
                                    <div className={'alert alert-info'}>{status.creationProgressLabel}</div>
                                    <div className="progress" style={{ height: "25px", "marginBottom": "15px" }}> 
                                        <div className={'progress-bar progress-bar-striped progress-bar-animated'} style={{width: status.creationProgress + "%", height: "25px"}}></div>
                                    </div>

                                </div>
                            }
                            <div className="form-group">
                                <button type="reset" className="btn btn-secondary float-left" disabled={isSubmitting}>Annuler</button>
                                <button type="submit" className="btn btn-danger float-right" disabled={isSubmitting}>Supprimer mon compte</button>
                                {isSubmitting &&
                                    <img alt='Veuillez patienter' src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export { DeleteAccountPage };