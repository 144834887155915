import { authHeader, handleResponse } from '../_helpers';

const API_URL = process.env.REACT_APP_API_URL;

export const stringService = {
    update
};

function update(STRINGID, FRAN, ENGL) {
    let header = authHeader();
    header['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    const requestOptions = { 
        method: 'PATCH', 
        headers:  header,
        body: new URLSearchParams({
            'FRAN': FRAN,
            'ENGL': ENGL
        })
    };

    return fetch(`${API_URL}/api/v1/string/${STRINGID}`, requestOptions).then(handleResponse);
}