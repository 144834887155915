import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

class CreateIntroPage extends React.Component {

    render() {
        return (
            <div>
                <h1>Maîtriser le présent,<br/>prévoir le future</h1>
                <div className="alert alert-info">
                    <p>
                    Citadel est une application de gestion d'actifs et de gestion de la maintenance assistée par ordinateur (GMAO), entièrement dédiée, configurée et spécialisée pour le secteur municipal.
                    </p>
                    <p>
                    L'assistant vous permet de créer et configurer facilement votre municipalité dans Citadel.
                    </p>
                    <p>
                    Veuillez prendre une minute pour consulter la politique de confidentialité de Citadel (lien à venir)
                    </p>
                </div>
                <Formik
                    initialValues={{}}
                    validationSchema={Yup.object().shape({})}
                    onSubmit={() => {
                        this.props.history.push('/create-municipality');
                    }}
                >
                    {({ errors, status, touched, isSubmitting }) => (
                        <Form>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary float-right" disabled={isSubmitting}>Commencer</button>
                                {isSubmitting &&
                                    <img alt='Veuillez patienter' src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                            {status &&
                                <div className={'alert alert-danger'}>{status}</div>
                            }
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export { CreateIntroPage };