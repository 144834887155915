import { apiKeyAuthHeader, postAuthHeader, handleResponse } from '../_helpers';

const API_URL = process.env.REACT_APP_API_URL;

export const accountService = {
    available,
    deletable,
    deleteTrial
};

function available(USER_NAME) {
    const requestOptions = { method: 'GET', headers: apiKeyAuthHeader() };
    return fetch(`${API_URL}/api/v1/trial/account/available?` + new URLSearchParams({
        USER_NAME: USER_NAME,
    }), requestOptions).then(handleResponse);
}

function deletable() {
    const requestOptions = { method: 'GET', headers: apiKeyAuthHeader() };
    return fetch(`${API_URL}/api/v1/trial/account/deletable`, requestOptions).then(handleResponse);
}

function deleteTrial() {
    return deleteTrialAccount()
    .catch(e => {
        throw new Error("Erreur lors de la suppression du compte: " + e);
    });
}

function deleteTrialAccount() {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
    };

    return fetch(`${API_URL}/api/v1/trial/account/initiateDeletion`, requestOptions).then(handleResponse);
}
