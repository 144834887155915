import { authHeader, postAuthHeader, handleResponse } from '../_helpers';
import { stringService, wizardPropertyService } from '../_services';

const API_URL = process.env.REACT_APP_API_URL;

export const warehouseService = {
    getList,
    create
};

function getList() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v2/warehouse`, requestOptions).then(handleResponse);
}

function create(COMPANY_NAME, COMPANY_PK) {

    let defaultWarehouseLabel = wizardPropertyService.getDefaultWarehouseLabel();
    let FRAN = defaultWarehouseLabel.FRAN;
    let ENGL = defaultWarehouseLabel.ENGL;

    let ID = COMPANY_PK + wizardPropertyService.getDefaultWarehouseIDSuffix();
    let FLAG_LOCAL = 'Y';
    let createdWarehouse;

    return createWarehouse(ID, FRAN, FLAG_LOCAL)
    .then(warehouse => {
        createdWarehouse = warehouse;
        stringService.update(warehouse.STRINGID, FRAN, ENGL);
        return warehouse;
    })
    .then(() => {
        return createWarehouseCompany(COMPANY_PK, createdWarehouse.WAREHOUSE_PK, 'N')
    })
    .then(() => createdWarehouse)
    .catch(e => {
        throw new Error("Erreur lors de la création du magasin: " + e);
    });
}

function createWarehouse(ID, DESIGNATION, FLAG_LOCAL) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ ID, DESIGNATION, FLAG_LOCAL })
    };

    return fetch(`${API_URL}/api/v2/warehouse`, requestOptions).then(handleResponse);
}

function createWarehouseCompany(COMPANY_PK, WAREHOUSE_PK, LINK_ALL) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ COMPANY_PK, WAREHOUSE_PK, LINK_ALL })
    };

    return fetch(`${API_URL}/api/v2/warehousecompany`, requestOptions).then(handleResponse);
}
