import React from 'react';

import { wizardPropertyService, employeeService, companyService, userService, accountService, managementUnitService } from '../_services';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import * as Yup from 'yup';

class CreateUserPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: null,
            municipalities: null,
            roles: null,
            managementUnits: null
        };
    }

    componentDidMount() {
        userService.getProfile().then(
            profile => this.setState({ profile })
        );
        companyService.getList().then(
            municipalities => this.setState({ municipalities })
        );
        managementUnitService.getList().then(
            managementUnits => this.setState({ managementUnits })
        );
        this.setState({ personas : wizardPropertyService.getMunicipalityPersonas() });
    }

    render() {
        const { profile, municipalities, personas, managementUnits } = this.state;
        const municipalityOptions = [];
        if (municipalities) {
            municipalities.sort(function (a, b) {
                return a.NAME.localeCompare(b.NAME);
            });

            for (let municipality of municipalities) {
                municipalityOptions.push(
                    <option key={municipality.COMPANY_PK} value={municipality.COMPANY_PK}>
                    {municipality.NAME}
                    </option>
                );
            }
        }   

        const personaOptions = [];
        if (personas) {
            Object.keys(personas).forEach(function(key) {
                let persona = personas[key];
                personaOptions.push(
                    <option key={key} value={key}>
                    {persona.FRAN}
                    </option>
                );
            });

        }

        const managementUnitOptions = [];
        if (managementUnits) {
            managementUnits.sort(function (a, b) {
                return a.DESIGNATION.localeCompare(b.DESIGNATION);
            });

            for (let managementUnit of managementUnits) {
                managementUnitOptions.push(
                    <option key={managementUnit.MANAGEMENT_UNIT_PK} value={managementUnit.MANAGEMENT_UNIT_PK}>
                    {managementUnit.DESIGNATION}
                    </option>
                );
            }
        }

        return (
            <div>
                <h1>Créer un utilisateur</h1>
                <div className="alert alert-info">
                    Veuillez indiquer les informations de base sur l'utilisateur à créer.<br/>
                    Les champs suivis d'un astérisque (*) sont obligatoires.
                </div>
                <Formik
                    enableReinitialize="true"
                    initialValues={{
                        COMPANY_PK: (municipalities ? municipalities[0].COMPANY_PK : ''),
                        USER_NAME: '',
                        NEW_PASSWORD: '',
                        MOD_ASSET: (personas ? Object.keys(personas)[0] : ''),
                        MOD_MAINT: (personas ? Object.keys(personas)[0] : ''),
                        MOD_STOCK: (personas ? Object.keys(personas)[0] : ''),
                        NAME: '',
                        MANAGEMENT_UNIT_PK: (managementUnits ? managementUnits[0].MANAGEMENT_UNIT_PK : ''),
                        GROUP_NAME: '',
                        FUNCTION: '',
                        EMAIL: '',
                        TEL: '', 
                        EXTENSION: ''
                    }}
                    validationSchema={Yup.object().shape({
                        COMPANY_PK: Yup.string()
                            .required("Veuillez sélectionner une municipalité."),
                        USER_NAME: Yup.string()
                            .required("Veuillez entrer un nom d’utilisateur.")
                            .matches(/^([a-zA-Z0-9\-._])*$/, "Le nom d’utilisateur peut contenir des lettres (a-z), chiffres (0-9), caractères de soulignement (_), tirets (-) et points (.)")
                            .min(3, "Le nom d’utilisateur doit contenir au moins 3 caractères.")
                            .max(40, "Le nom d’utilisateur ne doit pas contenir plus de 40 caractères.")
                            .test(
                                "available-user",
                                "Le nom d’utilisateur n'est pas disponible",
                                (value, context) => {
                                    if (!value || value.length < 3 || value.length > 40) {
                                        return true;
                                    }
                                    return accountService.available(value).then(response => response.available);
                                },
                              ),
                        NEW_PASSWORD: Yup.string()
                            .required("Veuillez entrer un mot de passe.")
                            .min(8, "Le mot de passe doit contenir au moins 8 caractères.")
                            .max(256, "Le mot de passe ne doit pas contenir plus de 256 caractères."),
                        MOD_ASSET: Yup.string()
                            .required("Veuillez sélectionner un rôle."),
                        MOD_MAINT: Yup.string()
                            .required("Veuillez sélectionner un rôle."),
                        MOD_STOCK: Yup.string()
                            .required("Veuillez sélectionner un rôle."),
                        NAME: Yup.string()
                            .required("Veuillez entrer un nom.")
                            .max(40, "Le nom ne doit pas contenir plus de 40 caractères."),
                        MANAGEMENT_UNIT_PK: Yup.string()
                            .required("Veuillez sélectionner un groupe de gestion."),
                        GROUP_NAME: Yup.string()
                            .max(250, "Le groupe ne doit pas contenir plus de 250 caractères."),
                        FUNCTION: Yup.string()
                            .max(50, "La fonction ne doit pas contenir plus de 50 caractères."),
                        EMAIL: Yup.string()
                            .email("Veuillez entrer une adresse courriel valide.")
                            .max(250, "L’adresse courriel ne doit pas contenir plus de 250 caractères."),
                        TEL: Yup.string()
                            .max(50, "Le numéro de téléphone ne doit pas contenir plus de 50 caractères."),
                        EXTENSION: Yup.number()
                            .typeError("Le numéro de poste doit être composé de chiffres uniquement.")
                            .max(9999999999, "Le numéro de poste ne doit pas contenir plus de 250 caractères.")
                    })}
                    onSubmit={({ COMPANY_PK, NAME, USER_NAME, NEW_PASSWORD, MOD_ASSET, MOD_MAINT, MOD_STOCK, MANAGEMENT_UNIT_PK, GROUP_NAME, FUNCTION, EMAIL, TEL, EXTENSION }, { setStatus, setSubmitting }) => {
                        if (MOD_ASSET === 'NONE' && MOD_MAINT === 'NONE' && MOD_STOCK === 'NONE') {
                            setSubmitting(false);
                            setStatus({ creationError : "Au moins au produit doit être sélectionné (Gestion des actifs, Gestion de la maintenance ou Gestion des stocks)"});
                            return;
                        }

                        setStatus({ creationProgressLabel : "Création de l'utilisateur ...", creationProgress: 10});
                        let createdUser;

                        userService.create(COMPANY_PK, USER_NAME, NEW_PASSWORD, profile.EMPLOYEE_PK, profile.USER_PK, MOD_ASSET, MOD_MAINT, MOD_STOCK, MANAGEMENT_UNIT_PK)
                        .then(user => {
                            setStatus({ creationProgressLabel : "Création de l'employé ...", creationProgress: 50});
                            createdUser = user;
                            return employeeService.create(profile.EMPLOYEE_PK, profile.FLAG_SYSTEM, COMPANY_PK, NAME, GROUP_NAME, FUNCTION, EMAIL, TEL, EXTENSION);
                        })
                        .then(employee => {
                            setStatus({ creationProgressLabel : "Association de l'employé à l'utilisateur ...", creationProgress: 90});
                            return userService.update(createdUser.USER_PK, COMPANY_PK, USER_NAME, employee.EMPLOYEE_PK, profile.USER_PK);
                        })
                        .then(user => {
                            setStatus({ creationProgressLabel : "Création terminée avec succès!", creationProgress: 100});
                            return new Promise(resolve => setTimeout(resolve, 2000))
                            .then(() => this.props.history.push("/"));
                        })
                        .catch(e => {
                            setSubmitting(false);
                            setStatus({ creationError : e.message || e || "Erreur lors de la création d'un élément de l'utilisateur"});
                        });

                    }}
                >
                    {({ errors, status, touched, isSubmitting }) => (
                        <Form>
                            {municipalities && municipalities.length > 1 &&
                            <div className="form-group row">
                                <label htmlFor="COMPANY_PK" className="col-sm-5 col-form-label">Municipalité *</label>
                                <div className="col-sm-6">
                                    <Field as="select" name="COMPANY_PK" className={'btn btn-info dropdown-toggle text-left form-control' + (errors.COMPANY_PK && touched.COMPANY_PK ? ' is-invalid' : '')}>
                                        {municipalityOptions}
                                    </Field>
                                    <ErrorMessage name="COMPANY_PK" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            }
                            <div className="form-group row">
                                <label htmlFor="MOD_ASSET" className="col-sm-5 col-form-label">Gestion des actifs</label>
                                <div className="col-sm-6">
                                    <Field as="select" name="MOD_ASSET" className={'btn btn-info dropdown-toggle text-left form-control' + (errors.MOD_ASSET && touched.MOD_ASSET ? ' is-invalid' : '')}>
                                        {personaOptions}
                                    </Field>
                                    <ErrorMessage name="MOD_ASSET" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="MOD_MAINT" className="col-sm-5 col-form-label">Gestion de la maintenance</label>
                                <div className="col-sm-6">
                                    <Field as="select" name="MOD_MAINT" className={'btn btn-info dropdown-toggle text-left form-control' + (errors.MOD_MAINT && touched.MOD_MAINT ? ' is-invalid' : '')}>
                                        {personaOptions}
                                    </Field>
                                    <ErrorMessage name="MOD_MAINT" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="MOD_STOCK" className="col-sm-5 col-form-label">Gestion des stocks</label>
                                <div className="col-sm-6">
                                    <Field as="select" name="MOD_STOCK" className={'btn btn-info dropdown-toggle text-left form-control' + (errors.MOD_STOCK && touched.MOD_STOCK ? ' is-invalid' : '')}>
                                        {personaOptions}
                                    </Field>
                                    <ErrorMessage name="MOD_STOCK" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="USER_NAME" className="col-sm-5 col-form-label">Nom d'utilisateur *</label>
                                <div className="col-sm-6">
                                    <Field name="USER_NAME" type="text" className={'form-control' + (errors.USER_NAME && touched.USER_NAME ? ' is-invalid' : '')} />
                                    <ErrorMessage name="USER_NAME" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="NEW_PASSWORD" className="col-sm-5 col-form-label">Mot de passe *</label>
                                <div className="col-sm-6">
                                    <Field name="NEW_PASSWORD" type="password" autoComplete="new-password" className={'form-control' + (errors.NEW_PASSWORD && touched.NEW_PASSWORD ? ' is-invalid' : '')} />
                                    <ErrorMessage name="NEW_PASSWORD" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="NAME" className="col-sm-5 col-form-label">Prénom et nom *</label>
                                <div className="col-sm-6">
                                    <Field name="NAME" type="text" className={'form-control' + (errors.NAME && touched.NAME ? ' is-invalid' : '')} />
                                    <ErrorMessage name="NAME" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            {managementUnits && managementUnits.length > 1 &&
                            <div className="form-group row">
                                <label htmlFor="MANAGEMENT_UNIT_PK" className="col-sm-5 col-form-label">Groupe de gestion *</label>
                                <div className="col-sm-6">
                                    <Field as="select" name="MANAGEMENT_UNIT_PK" className={'btn btn-info dropdown-toggle text-left form-control' + (errors.MANAGEMENT_UNIT_PK && touched.MANAGEMENT_UNIT_PK ? ' is-invalid' : '')}>
                                        {managementUnitOptions}
                                    </Field>
                                    <ErrorMessage name="MANAGEMENT_UNIT_PK" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            }
                            <div className="form-group row">
                                <label htmlFor="GROUP_NAME" className="col-sm-5 col-form-label">Groupe</label>
                                <div className="col-sm-6">
                                    <Field name="GROUP_NAME" type="text" className={'form-control' + (errors.GROUP_NAME && touched.GROUP_NAME ? ' is-invalid' : '')} />
                                    <ErrorMessage name="GROUP_NAME" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="FUNCTION" className="col-sm-5 col-form-label">Fonction</label>
                                <div className="col-sm-6">
                                    <Field name="FUNCTION" type="text" className={'form-control' + (errors.FUNCTION && touched.FUNCTION ? ' is-invalid' : '')} />
                                    <ErrorMessage name="FUNCTION" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="EMAIL" className="col-sm-5 col-form-label">Courriel</label>
                                <div className="col-sm-6">
                                    <Field name="EMAIL" type="text" className={'form-control' + (errors.EMAIL && touched.EMAIL ? ' is-invalid' : '')} />
                                    <ErrorMessage name="EMAIL" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="TEL" className="col-sm-5 col-form-label">Téléphone</label>
                                <div className="col-sm-6">
                                    <Field name="TEL" type="text" className={'form-control' + (errors.TEL && touched.TEL ? ' is-invalid' : '')} />
                                    <ErrorMessage name="TEL" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="EXTENSION" className="col-sm-5 col-form-label">Numéro de poste</label>
                                <div className="col-sm-6">
                                    <Field name="EXTENSION" type="text" className={'form-control' + (errors.EXTENSION && touched.EXTENSION ? ' is-invalid' : '')} />
                                    <ErrorMessage name="EXTENSION" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            {status && status.creationError && 
                                <div className={'alert alert-danger'}>{status.creationError}</div>
                            }
                            {status && status.creationProgress &&
                                <div>
                                    <div className={'alert alert-info'}>{status.creationProgressLabel}</div>
                                    <div className="progress" style={{ height: "25px", "marginBottom": "15px" }}> 
                                        <div className={'progress-bar progress-bar-striped progress-bar-animated'} style={{width: status.creationProgress + "%", height: "25px"}}></div>
                                    </div>

                                </div>
                            }
                            <div className="form-group">
                                <button type="reset" className="btn btn-primary float-left" disabled={isSubmitting}>Réinitialiser</button>
                                <button type="submit" className="btn btn-primary float-right" disabled={isSubmitting}>Créer l'utilisateur</button>
                                {isSubmitting &&
                                    <img alt='Veuillez patienter' src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export { CreateUserPage };