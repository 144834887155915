import { authenticationManager } from '../_managers';

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationManager.logout();
                // TODO Reload may still be needed when token expire
                //location.reload(true);
            }

            let error = (data && data.message) || response.statusText;
            
            // Overrive NJ error message if 401 Unauthorized
            if (response.status === 401) {
                error = "Nom d'utilisateur ou mot de passe invalide";
            }
            return Promise.reject(error);
        }

        return data;
    });
}