import { authenticationManager } from '../_managers';

const API_KEY = process.env.REACT_APP_API_KEY;

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationManager.currentUserValue;
    if (currentUser && currentUser.token) {
        return { "x-access-token": currentUser.token };
    } else {
        return {};
    }
}

export function postAuthHeader() {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    return headers;
}

export function apiKeyAuthHeader() {
    let headers = authHeader();
    headers["x-api-key"] = API_KEY;
    return headers;
}