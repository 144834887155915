import { authHeader, postAuthHeader, handleResponse, buildMunicipalityName } from '../_helpers';
import { wizardPropertyService } from '../_services';

const API_URL = process.env.REACT_APP_API_URL;

export const clientService = {
    getList,
    create
};

function getList() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v1/client`, requestOptions).then(handleResponse);
}

function create(COMPANY_NAME, COMPANY_PK, ADDRESS, TEL, FAX, STATUTE) {

    let municipalityName = buildMunicipalityName(COMPANY_NAME, STATUTE);

    let munID = COMPANY_PK + wizardPropertyService.getDefaultMunClientPKSuffix();
    let privID = COMPANY_PK + wizardPropertyService.getDefaultPrivClientPKSuffix();
    let mtqID = COMPANY_PK + wizardPropertyService.getDefaultMtqClientPKSuffix();

    let topOwnerPK = COMPANY_PK + wizardPropertyService.getDefaultTopOwnerPKSuffix();

    return Promise.all([
        createClient(munID, municipalityName.FRAN, ADDRESS, TEL, FAX),
        createClient(privID, wizardPropertyService.getDefaultPrivClientLabel(), null, null, null),
        createClient(mtqID, wizardPropertyService.getDefaultMtqClientLabel(), null, null, null)
    ])
    .then(clients => Promise.all([
        // Clients must exist before the creation of the activity association
        createClientActivity(munID, 'MUN'),
        createClientActivity(privID, 'RES'),
        createClientActivity(privID, 'IND'),
        createClientActivity(privID, 'INST'),
        createClientActivity(privID, 'COM'),
        createClientActivity(privID, 'AUTRE'),
        createClientActivity(mtqID, 'GOUV'),
    ]))
    .then(activities => Promise.all([
        // Clients must exist before the creation of the company-agency association
        createClientCompany(munID, COMPANY_PK, 'Y'),
        createClientCompany(privID, COMPANY_PK, 'Y'),
        createClientCompany(mtqID, COMPANY_PK, 'Y'),
    ]))
    .then(companyagencies => Promise.all([
        // Clients must exist before the creation of the owner association
        createClientOwner(munID, topOwnerPK, 'Y'),
        createClientOwner(privID, topOwnerPK, 'Y'),
        createClientOwner(mtqID, topOwnerPK, 'Y'),
    ]))
    .catch(e => {
        throw new Error("Erreur lors de la création des gestionnaires d'actif: " + e);
    });
}

function createClient(ID, NAME, ADDRESS, TEL, FAX ) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ ID, NAME, ADDRESS, TEL, FAX })
    };

    return fetch(`${API_URL}/api/v1/client`, requestOptions).then(handleResponse);
}

function createClientActivity(CLIENT_PK, ACTIVITY_PK) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ CLIENT_PK, ACTIVITY_PK })
    };

    return fetch(`${API_URL}/api/v1/client/activity`, requestOptions).then(handleResponse);
}

function createClientCompany(CLIENT_PK, COMPANY_PK, FLAG_GLOBAL) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ CLIENT_PK, COMPANY_PK, FLAG_GLOBAL })
    };

    return fetch(`${API_URL}/api/v1/client/company`, requestOptions).then(handleResponse);
}

function createClientOwner(CLIENT_PK, OWNER_PK, ADD_CHILD) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ CLIENT_PK, OWNER_PK, ADD_CHILD })
    };

    return fetch(`${API_URL}/api/v1/client/owner`, requestOptions).then(handleResponse);
}
