import { companyService, zoneService, teamService, warehouseService, ownerService, clientService } from '../_services';
import { authenticationManager } from '../_managers';

export const municipalityManager = {
    verifyId,
    permissionToListMunicipalities,
    permissionToCreateMunicipality,
    permissionToCreateUser,
    permissionToDeleteMyAccount
};

function verifyId(ID) {
    return companyService.getList().then(companies => {
        for (let company of companies) {
            if (company.COMPANY_PK === ID) {
                throw new Error("L'identifiant " + ID + " n'est pas disponible (pour le composant Municipalité)");
            }
         };
    })
    .then(() => {
        return zoneService.getList().then(zones => {
            for (let zone of zones) {
                if (zone.ZONE_PK.startsWith(ID)) {
                    throw new Error("L'identifiant " + ID + " n'est pas disponible (pour le composant Zone)");
                }
            }
        })
    })
    .then(() => {
        return teamService.getList().then(teams => {
            for (let team of teams) {
                if (team.TEAM_PK.startsWith(ID)) {
                    throw new Error("L'identifiant " + ID + " n'est pas disponible (pour le composant Équipe)");
                }
            }
        })
    })
    .then(() => {
        return warehouseService.getList().then(warehouses => {
            for (let warehouse of warehouses) {
                if (warehouse.WAREHOUSE_PK.startsWith(ID)) {
                    throw new Error("L'identifiant " + ID + " n'est pas disponible (pour le composant Magasin)");
                }
            }
        })
    })
    .then(() => {
        return ownerService.getList().then(owners => {
            for (let owner of owners) {
                if (owner.OWNER_PK.startsWith(ID)) {
                    throw new Error("L'identifiant " + ID + " n'est pas disponible (pour le composant Département)");
                }
            }
        })
    })
    .then(() => {
        return clientService.getList().then(clients => {
            for (let client of clients) {
                if (client.CLIENT_PK.startsWith(ID)) {
                    throw new Error("L'identifiant " + ID + " n'est pas disponible (pour le composant Gestionnaire d'actif)");
                }
            }
        })
    })
}

function permissionToListMunicipalities() {
    let permissions = authenticationManager.currentPermissionsValue;

    return permissions && 
    permissions['NJ_PAGE_COMPANY.SELECT'] === 'Y';
}

function permissionToCreateMunicipality() {
    let permissions = authenticationManager.currentPermissionsValue;

    return permissions && 
    permissions['NJ_PAGE_COMPANY.INSERT'] === 'Y' && 
    permissions['NJ_PAGE_AGENCY.INSERT'] === 'Y' && 
    permissions['NJ_PAGE_MANAGEMENT_UNIT.INSERT'] === 'Y' && 
    permissions['NJ_PAGE_ZONE.INSERT'] === 'Y' && 
    permissions['NJ_PAGE_TEAM.INSERT'] === 'Y' && 
    permissions['NJ_PAGE_WAREHOUSE.INSERT'] === 'Y' && 
    permissions['NJ_PAGE_OWNER.INSERT'] === 'Y' && 
    permissions['NJ_PAGE_CLIENT.INSERT'] === 'Y';
}

function permissionToCreateUser() {
    let permissions = authenticationManager.currentPermissionsValue;

    return permissions && 
    permissions['NJ_PAGE_EMPLOYEE.INSERT'] === 'Y' && 
    permissions['NJ_PAGE_EMPLOYEE.UPDATE'] === 'Y' && 
    permissions['NJ_PAGE_USER.INSERT'] === 'Y' && 
    permissions['NJ_PAGE_USER.UPDATE'] === 'Y';
}

function permissionToDeleteMyAccount() {
    let permissions = authenticationManager.currentPermissionsValue;

    return permissions && 
    permissions['deletable'] === 'Y' &&
    permissions['NJ_PAGE_PROFILE.UPDATE'] === 'Y';
}