import { authHeader, postAuthHeader, handleResponse } from '../_helpers';
import { wizardPropertyService } from '../_services';

const API_URL = process.env.REACT_APP_API_URL;

export const employeeService = {
    getList,
    create
};

function getList() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v1/employee`, requestOptions).then(handleResponse);
}

function create(PARENT_EMPLOYEE_PK, FLAG_SYSTEM, COMPANY_PK, NAME, GROUP_NAME, FUNCTION, EMAIL, TEL, EXTENSION) {

    let createdEmployee;

    return getList()
    .then(employees => {
        // Find next employee ID
        let empNumberMax = 0;
        for (let employee of employees) {
            let myRegexp = new RegExp("^" + COMPANY_PK +"(\\d*?)$", "g");
            var match = myRegexp.exec(employee.EMPLOYEE_PK);
            if (match) {
                let empNumber = parseInt(match[1]);
                if (empNumber > empNumberMax) {
                    empNumberMax = empNumber;
                }
            }
        }
        let zeroFilledEmpNum = ('000'+ (empNumberMax+1)).slice(-3);
        let ID = COMPANY_PK + zeroFilledEmpNum;

        // Default team
        let TEAM_PK = COMPANY_PK + wizardPropertyService.getDefaultTeamIDSuffix();

        // Employee is active by default
        let STATUS = 'Y';

        return createEmployee(ID, NAME, TEAM_PK, GROUP_NAME, FUNCTION, EMAIL, STATUS, PARENT_EMPLOYEE_PK, TEL, EXTENSION)
        .then(employee => {
            createdEmployee = employee;
            return createEmployeeList(employee.EMPLOYEE_PK, null, 'Y');
        })
        .then(() => {
            let topOwnerPK = COMPANY_PK + wizardPropertyService.getDefaultTopOwnerPKSuffix();
            return createEmployeeOwner(createdEmployee.EMPLOYEE_PK, topOwnerPK, 'Y');
        })
        .then(() => {
            if (FLAG_SYSTEM === 'Y') {
                // SYSTEM users do not have automatic company assignment
                return createEmployeeCompany(createdEmployee.EMPLOYEE_PK, COMPANY_PK);
            } else {
                return null;
            }
        })
        .then(() => {
            let munID = COMPANY_PK + wizardPropertyService.getDefaultMunClientPKSuffix();
            let privID = COMPANY_PK + wizardPropertyService.getDefaultPrivClientPKSuffix();
            let mtqID = COMPANY_PK + wizardPropertyService.getDefaultMtqClientPKSuffix();
            return Promise.all([
                createEmployeeClient(createdEmployee.EMPLOYEE_PK, munID),
                createEmployeeClient(createdEmployee.EMPLOYEE_PK, privID),
                createEmployeeClient(createdEmployee.EMPLOYEE_PK, mtqID)
            ])
        })
        .then(() => createdEmployee)
        .catch(e => {
            throw new Error("Erreur lors de la création de l'employé: " + e);
        });
    })

}

function createEmployee(ID, NAME, TEAM_PK, GROUP_NAME, FUNCTION, EMAIL, STATUS, PARENT_EMPLOYEE_PK, TEL, EXTENSION) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ ID, NAME, TEAM_PK, GROUP_NAME, FUNCTION, EMAIL, STATUS, PARENT_EMPLOYEE_PK, TEL, EXTENSION })
    };

    return fetch(`${API_URL}/api/v1/employee`, requestOptions).then(handleResponse);
}

function createEmployeeList(EMPLOYEE_PK, LIST_PK, LINK_ALL) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ EMPLOYEE_PK, LIST_PK, LINK_ALL })
    };

    return fetch(`${API_URL}/api/v1/employee/list`, requestOptions).then(handleResponse);
}

function createEmployeeOwner(EMPLOYEE_PK, OWNER_PK, ADD_CHILD) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ EMPLOYEE_PK, OWNER_PK, ADD_CHILD })
    };

    return fetch(`${API_URL}/api/v1/employee/owner`, requestOptions).then(handleResponse);
}

function createEmployeeClient(EMPLOYEE_PK, CLIENT_PK) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ EMPLOYEE_PK, CLIENT_PK })
    };

    return fetch(`${API_URL}/api/v1/employee/client`, requestOptions).then(handleResponse);
}

function createEmployeeCompany(EMPLOYEE_PK, COMPANY_PK) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ EMPLOYEE_PK, COMPANY_PK })
    };

    return fetch(`${API_URL}/api/v1/employee/company`, requestOptions).then(handleResponse);
}