const startWithVowel = chr => (/[aeiou]/i).test(chr.normalize('NFD').split('')[0]);

export function buildMunicipalityName(NAME, STATUTE) {
    let namePrefixFr = '';
    let namePrefixEn = '';

    if (STATUTE === 'municipality' || STATUTE === 'village_municipality' || STATUTE === 'township_municipality' || STATUTE === 'parish_municipality') {
        namePrefixFr = 'Municipalité ';
        namePrefixEn = 'Municipality ';
    } else if (STATUTE === 'indian_reserve') {
        namePrefixFr = 'Réserve indienne ';
        namePrefixEn = 'Indian Reserve ';
    } else if (STATUTE === 'city') {
        namePrefixFr = 'Ville ';
        namePrefixEn = 'City ';
    }

    if (startWithVowel(NAME)) {
        namePrefixFr += 'd\'';
    } else {
        namePrefixFr += 'de ';
    }
    namePrefixEn += 'of ';

    let FRAN = namePrefixFr + NAME;
    let ENGL = namePrefixEn + NAME;

    return { 'ENGL': ENGL, 'FRAN': FRAN};
}
