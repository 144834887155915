import { authHeader, postAuthHeader, handleResponse } from '../_helpers';
import { stringService, wizardPropertyService } from '../_services';

const API_URL = process.env.REACT_APP_API_URL;

export const teamService = {
    getList,
    create
};

function getList() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v1/team`, requestOptions).then(handleResponse);
}

function create(COMPANY_NAME, COMPANY_PK, ZONE_PK) {

    let FRAN = COMPANY_NAME;
    let ENGL = COMPANY_NAME;

    let ID = COMPANY_PK + wizardPropertyService.getDefaultTeamIDSuffix();

    return createTeam(ID, FRAN, ZONE_PK).then(team => {
        stringService.update(team.STRINGID, FRAN, ENGL);
        return team;
    }).catch(e => {
        throw new Error("Erreur lors de la création de l'équipe: " + e);
    });
}

function createTeam(ID, DESIGNATION, ZONE_PK) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ ID, DESIGNATION, ZONE_PK })
    };

    return fetch(`${API_URL}/api/v1/team`, requestOptions).then(handleResponse);
}
