import { authHeader, postAuthHeader, handleResponse } from '../_helpers';
import { stringService } from '../_services';

const API_URL = process.env.REACT_APP_API_URL;

export const agencyService = {
    getList,
    create
};

function getList() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v1/agency`, requestOptions).then(handleResponse);
}

function create(COMPANY_NAME, COMPANY_PK) {

    let FRAN = COMPANY_NAME;
    let ENGL = COMPANY_NAME;

    return createAgency(FRAN, COMPANY_PK).then(agency => {
        stringService.update(agency.STRINGID, FRAN, ENGL);
        return agency;
    }).catch(e => {
        throw new Error("Erreur lors de la création du service: " + e);
    });
}

function createAgency(DESIGNATION, COMPANY_PK) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ DESIGNATION, COMPANY_PK })
    };

    return fetch(`${API_URL}/api/v1/agency`, requestOptions).then(handleResponse);
}
