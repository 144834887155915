import { authHeader, postAuthHeader, handleResponse } from '../_helpers';
import { stringService } from '../_services';

const API_URL = process.env.REACT_APP_API_URL;

export const managementUnitService = {
    getList,
    create
};

function getList() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v1/managementunit`, requestOptions).then(handleResponse);
}

function create(COMPANY_NAME) {

    let FRAN = COMPANY_NAME;
    let ENGL = COMPANY_NAME;

    return createManagementUnit(FRAN).then(managementUnit => {
        stringService.update(managementUnit.STRINGID, FRAN, ENGL);
        return managementUnit;
    }).catch(e => {
        throw new Error("Erreur lors de la création du groupe de gestion: " + e);
    });
}

function createManagementUnit(DESIGNATION) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ DESIGNATION })
    };

    return fetch(`${API_URL}/api/v1/managementunit`, requestOptions).then(handleResponse);
}
