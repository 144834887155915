import React from 'react';
import {Link } from 'react-router-dom';

import { userService, companyService } from '../_services';
import { authenticationManager, municipalityManager } from '../_managers';

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: authenticationManager.currentUserValue,
            profile: null,
            municipalities: null,
            users: null
        };
    }

    componentDidMount() {
        userService.getProfile().then(
            profile => this.setState({ profile })
        );
        if (municipalityManager.permissionToListMunicipalities()) {
            companyService.getList().then(
                municipalities => this.setState({ municipalities })
            );
        }
        userService.getList().then(
            users => this.setState({ users })
        );
    }

    render() {
        const { currentUser, municipalities, users } = this.state;

        return (
            <div>
                <h1>Bonjour {currentUser.user.employeename || currentUser.user.username}!</h1>

                {!municipalityManager.permissionToDeleteMyAccount() &&
                    <div className="alert alert-info">
                        Bienvenue dans l'Assistant Citadel {process.env.REACT_APP_VERSION}.<br />
                        Nous vous guiderons dans l'initialisation de votre municipalité.<br />
                        N'hésitez pas à nous contacter à partir du <a target="_blank" rel="noreferrer" href="https://citadelapp.zendesk.com">Centre d'aide de Citadel</a> en cas de besoin.
                    </div>
                }

                {municipalityManager.permissionToDeleteMyAccount() &&
                    <div className="alert alert-info">
                        Bienvenue dans l'Assistant Citadel {process.env.REACT_APP_VERSION}.<br />
                        Afin de mettre fin à votre essaie de l'application mobile Citadel, vous pouvez supprimer votre compte ici.
                    </div>
                }

                {municipalities && !municipalityManager.permissionToDeleteMyAccount() &&
                    <div>
                        <h3>Vos municipalités</h3>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Nom</th>
                                </tr>
                            </thead>
                            <tbody>
                                {municipalities.map(municipality =>
                                    <tr key={municipality.COMPANY_PK}>
                                        <td>{municipality.COMPANY_PK}</td>
                                        <td>{municipality.NAME}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                }

                {users && !municipalityManager.permissionToDeleteMyAccount() &&
                    <div>
                        <h3>Vos utilisateurs</h3>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Nom d'utilisateur</th>
                                    <th scope="col">Nom d'employé</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(user =>
                                    <tr key={user.USER_PK}>
                                        <td>{user.USER_PK}</td>
                                        <td>{user.USER_NAME}</td>
                                        <td>{user.EMPLOYEE_NAME}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                }

                {municipalityManager.permissionToDeleteMyAccount() &&
                    <div>
                        <Link to="/delete-my-account">
                            <button className="btn btn-danger float-right">Supprimer mon compte</button>
                        </Link>
                    </div>
                }

            </div>
        );
    }
}

export { HomePage };