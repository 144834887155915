export const promiseEach = (arrayOfTasks) => {
    let results = []
    return new Promise((resolve, reject) => {
        const resolveNext = (arrayOfTasks) => {
        // If all tasks are already resolved, return the final array of results
        if (arrayOfTasks.length === 0) return resolve(results)
    
        // Extract first promise and solve it
        const first = arrayOfTasks.shift()
    
        first().then((res) => {
            results.push(res)
            resolveNext(arrayOfTasks)
        }).catch((err) => {
            reject(err)
        })
        }
        resolveNext(arrayOfTasks)
    })
}