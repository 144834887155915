import { authHeader, postAuthHeader, handleResponse, buildMunicipalityName } from '../_helpers';
import { stringService } from '../_services';

const API_URL = process.env.REACT_APP_API_URL;

export const companyService = {
    getList,
    create
};

function getList() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v1/company`, requestOptions).then(handleResponse);
}

function create(ID, NAME, ADDRESS, TEL, FAX, STATUTE) {

    let municipalityName = buildMunicipalityName(NAME, STATUTE);

    return createCompany(ID, municipalityName.FRAN, municipalityName.FRAN, ADDRESS, TEL, FAX).then(company => {
        stringService.update(company.STRINGID, municipalityName.FRAN, municipalityName.ENGL);
        return company;
    }).catch(e => {
        throw new Error("Erreur lors de la création de la municipalité: " + e);
    });
}

function createCompany(ID, DESIGNATION, NAME, ADDRESS, TEL, FAX) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ ID, DESIGNATION, NAME, ADDRESS, TEL, FAX })
    };

    return fetch(`${API_URL}/api/v1/company`, requestOptions).then(handleResponse);
}
