import { authHeader, postAuthHeader, handleResponse, buildMunicipalityName, promiseEach } from '../_helpers';
import { stringService, wizardPropertyService, userService } from '../_services';

const API_URL = process.env.REACT_APP_API_URL;

export const ownerService = {
    getList,
    create
};

function getList() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${API_URL}/api/v1/owner`, requestOptions).then(handleResponse);
}

function create(COMPANY_NAME, COMPANY_PK, COMPANY_STATUTE) {

    let defaultOwners = wizardPropertyService.getDefaultOwners()
    let newOwners = [];

    defaultOwners.forEach((defaulfOwner) => {
        let owner = JSON.parse(JSON.stringify(defaulfOwner));
        owner.OWNER_PK = COMPANY_PK + owner.OWNER_PK;
        owner.OWNER_NODE = COMPANY_PK + owner.OWNER_NODE;
        newOwners.push(owner);
    });

    let topOwnerPK = COMPANY_PK + wizardPropertyService.getDefaultTopOwnerPKSuffix();
    let municipalityName = buildMunicipalityName(COMPANY_NAME, COMPANY_STATUTE);
    
    // Top owner need to be first in the list
    newOwners.unshift({'OWNER_PK': topOwnerPK , 'OWNER_NODE': '' , 'ENGL': municipalityName.ENGL , 'FRAN': municipalityName.FRAN});
    
    const createOwnersTasks = newOwners.map(newOwner => () => {
        return createOwner(newOwner.OWNER_PK, newOwner.FRAN, newOwner.OWNER_NODE)
        .then(owner => {
            stringService.update(owner.STRINGID, newOwner.FRAN, newOwner.ENGL);
            return owner;
        })
    });

    return promiseEach(createOwnersTasks)
    // array of created owners
    .then(() => {
        // TODO Workaround: until owner visibility is not fixed by company, we must reproduce legacy behavior of adding SENERGY as a first user
        let topOwnerPK = COMPANY_PK + wizardPropertyService.getDefaultTopOwnerPKSuffix();
        return userService.createUserOwner(0, topOwnerPK, 'Y');
    })
    .then(() => {
        const ownerLinksPromises = [];

        // wastewater
        let wastewaterRecoveryOwnerPK = COMPANY_PK + wizardPropertyService.getWastewaterRecoveryOwnerPKSuffix();
        let wastewaterRecoveryCategories = wizardPropertyService.getWastewaterRecoveryCategories();
        wastewaterRecoveryCategories.map((category) => ownerLinksPromises.push(createOwnerCategory(wastewaterRecoveryOwnerPK, category, 'Y')));

        let wastewaterPurificationOwnerPK = COMPANY_PK + wizardPropertyService.getWastewaterPurificationOwnerPKSuffix();
        let wastewaterPurificationCategories = wizardPropertyService.getWastewaterPurificationCategories();
        wastewaterPurificationCategories.map((category) => ownerLinksPromises.push(createOwnerCategory(wastewaterPurificationOwnerPK, category, 'Y')));

        let wastewaterCollectionOwnerPK = COMPANY_PK + wizardPropertyService.getWastewaterCollectionOwnerPKSuffix();
        let wastewaterCollectionCategories = wizardPropertyService.getWastewaterCollectionCategories();
        wastewaterCollectionCategories.map((category) => ownerLinksPromises.push(createOwnerCategory(wastewaterCollectionOwnerPK, category, 'Y')));

        let wastewaterPumpingOwnerPK = COMPANY_PK + wizardPropertyService.getWastewaterPumpingOwnerPKSuffix();
        let wastewaterPumpingCategories = wizardPropertyService.getWastewaterPumpingCategories();
        wastewaterPumpingCategories.map((category) => ownerLinksPromises.push(createOwnerCategory(wastewaterPumpingOwnerPK, category, 'Y')));

        // drinking water
        let drinkingWaterSupplyOwnerPK = COMPANY_PK + wizardPropertyService.getDrinkingWaterSupplyOwnerPKSuffix();
        let drinkingWaterSupplyCategories = wizardPropertyService.getDrinkingWaterSupplyCategories();
        drinkingWaterSupplyCategories.map((category) => ownerLinksPromises.push(createOwnerCategory(drinkingWaterSupplyOwnerPK, category, 'Y')));

        let drinkingWaterDistributionOwnerPK = COMPANY_PK + wizardPropertyService.getDrinkingWaterDistributionOwnerPKSuffix();
        let drinkingWaterDistributionCategories = wizardPropertyService.getDrinkingWaterDistributionCategories();
        drinkingWaterDistributionCategories.map((category) => ownerLinksPromises.push(createOwnerCategory(drinkingWaterDistributionOwnerPK, category, 'Y')));

        let drinkingWaterSupplyTreatementOwnerPK = COMPANY_PK + wizardPropertyService.getDrinkingWaterSupplyTreatementOwnerPKSuffix();
        let drinkingWaterSupplyTreatementCategories = wizardPropertyService.getDrinkingWaterSupplyTreatementCategories();
        drinkingWaterSupplyTreatementCategories.map((category) => ownerLinksPromises.push(createOwnerCategory(drinkingWaterSupplyTreatementOwnerPK, category, 'Y')));

        let drinkingWaterDistributionFailureCauses = wizardPropertyService.getDrinkingWaterDistributionFailureCauses();
        drinkingWaterDistributionFailureCauses.map((failureCause) => ownerLinksPromises.push(createOwnerFailureCause(drinkingWaterDistributionOwnerPK, failureCause)));

        // rainwater
        let rainwaterOwnerPK = COMPANY_PK + wizardPropertyService.getRainwaterOwnerPKSuffix();
        let rainwaterCategories = wizardPropertyService.getRainwaterCategories();
        rainwaterCategories.map((category) => ownerLinksPromises.push(createOwnerCategory(rainwaterOwnerPK, category, 'Y')));

        let rainwaterTreatmentAndRetentionOwnerPK = COMPANY_PK + wizardPropertyService.getRainwaterTreatmentAndRetentionOwnerPKSuffix();
        let rainwaterTreatmentAndRetentionCategories = wizardPropertyService.getRainwaterTreatmentAndRetentionCategories();
        rainwaterTreatmentAndRetentionCategories.map((category) => ownerLinksPromises.push(createOwnerCategory(rainwaterTreatmentAndRetentionOwnerPK, category, 'Y')));

        let rainwaterCollectionOwnerPK = COMPANY_PK + wizardPropertyService.getRainwaterCollectionOwnerPKSuffix();
        let rainwaterCollectionCategories = wizardPropertyService.getRainwaterCollectionCategories();
        rainwaterCollectionCategories.map((category) => ownerLinksPromises.push(createOwnerCategory(rainwaterCollectionOwnerPK, category, 'Y')));

        // roadway
        let roadwayOwnerOwnerPK = COMPANY_PK + wizardPropertyService.getRoadwayOwnerPKSuffix();
        let roadwayOwnerCategories = wizardPropertyService.getRoadwayCategories();
        roadwayOwnerCategories.map((category) => ownerLinksPromises.push(createOwnerCategory(roadwayOwnerOwnerPK, category, 'Y')));

        return Promise.all(ownerLinksPromises);
    })
    // some promise failed
    .catch((e) => {
        console.log("ERROR: " + e);
        throw new Error("Erreur lors de la création des départements: " + e);
    })
}

function createOwner(OWNER_PK, DESIGNATION, PARENT_OWNER_PK) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ OWNER_PK, DESIGNATION, PARENT_OWNER_PK })
    };

    return fetch(`${API_URL}/api/v1/owner`, requestOptions).then(handleResponse);
}

function createOwnerCategory(OWNER_PK, CATEGORY_PK, ADD_CHILD) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ OWNER_PK, CATEGORY_PK, ADD_CHILD })
    };

    return fetch(`${API_URL}/api/v1/owner/category`, requestOptions).then(handleResponse);
}

function createOwnerFailureCause(OWNER_PK, FAILURE_CAUSE_PK) {
    const requestOptions = { 
        method: 'POST', 
        headers:  postAuthHeader(),
        body: JSON.stringify({ OWNER_PK, FAILURE_CAUSE_PK })
    };

    return fetch(`${API_URL}/api/v1/owner/failurecause`, requestOptions).then(handleResponse);
}